<template>
    <div class="webapp-container">
        <router-view />
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.webapp-container {
    height: 100vh;
}
</style>